import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'

enum Variant {
  GRAY = 'GRAY',
  ORANGE = 'ORANGE',
  GREEN = 'GREEN',
}

enum Spacing {
  DEFAULT = 'DEFAULT',
  DENSE = 'DENSE',
  NONE = 'NONE',
}

export interface ContainerProps extends Props {
  variant?: Variant
  spacing?: Spacing
}

const THEME_VARIANT_MAPS: Record<Theme, Record<Variant, string>> = {
  [Theme.GREEN]: {
    [Variant.GRAY]: 'bg-gray-300',
    [Variant.ORANGE]: 'bg-orange-500 text-white',
    [Variant.GREEN]: 'bg-green-500 text-white',
  },
  [Theme.ORANGE]: {
    [Variant.GRAY]: 'bg-gray-300',
    [Variant.ORANGE]: 'bg-orange-500 text-white',
    [Variant.GREEN]: 'bg-green-500 text-white',
  },
} as const

const SPACING_MAPS: Record<Spacing, string> = {
  [Spacing.DEFAULT]: 'py-18 md:py-38',
  [Spacing.DENSE]: 'py-18 md:py-30',
  [Spacing.NONE]: 'py-0',
} as const

const Container = ({
  children,
  theme,
  variant,
  spacing = Spacing.DEFAULT,
  className = '',
  style,
}: ContainerProps) => {
  const themeVariantClassName =
    !isNullOrUndefined(variant) && !isNullOrUndefined(theme)
      ? THEME_VARIANT_MAPS[theme!.toUpperCase() as Theme][variant!]
      : null
  const spacingClassName = !isNullOrUndefined(spacing) ? SPACING_MAPS[spacing!] : null

  return (
    <div
      className={classNames(
        'flex w-full flex-col gap-18 md:gap-38',
        className,
        spacingClassName,
        themeVariantClassName,
      )}
      style={style}
    >
      {children}
    </div>
  )
}

Container.variant = Variant
Container.spacing = Spacing

export default Container
