import isNullOrUndefined from '@utils/isNullOrUndefined'
import delve from 'dlv'
import dynamic from 'next/dynamic'

const Highlights = dynamic(() => import('@components/blocks/Highlights'), {
  ssr: true,
})
const Testimonials = dynamic(() => import('@components/blocks/Testimonials'), {
  ssr: true,
})
const Heading = dynamic(() => import('@components/blocks/Heading'), { ssr: true })
const Kiyoh = dynamic(() => import('@components/blocks/Kiyoh'), { ssr: true })
const Button = dynamic(() => import('@components/blocks/Button'), { ssr: true })
const Tagline = dynamic(() => import('@components/blocks/Tagline'), { ssr: true })
const Html = dynamic(() => import('@components/blocks/Html'), { ssr: true })
const Faq = dynamic(() => import('@components/blocks/Faq'), { ssr: true })
const Image = dynamic(() => import('@components/blocks/Image'), { ssr: true })
const ImageWithFlare = dynamic(() => import('@components/blocks/ImageWithFlare'), { ssr: true })
const ImageWithBubbles = dynamic(() => import('@components/blocks/ImageWithBubbles'), { ssr: true })
const ImageWithText = dynamic(() => import('@components/blocks/ImageWithText'), { ssr: true })
const ImageSlideshow = dynamic(() => import('@components/blocks/ImageSlideshow'), { ssr: true })
const TextWithIcon = dynamic(() => import('@components/blocks/TextWithIcon'), { ssr: true })
const Timeline = dynamic(() => import('@components/blocks/Timeline'), { ssr: true })
const Features = dynamic(() => import('@components/blocks/Features'), { ssr: true })
const Statistics = dynamic(() => import('@components/blocks/Statistics'), { ssr: true })
const InfoBlocks = dynamic(() => import('@components/blocks/InfoBlocks'), { ssr: true })
const Brand = dynamic(() => import('@components/shared/graphics/Brand'), { ssr: true })
const ProductBenefits = dynamic(() => import('@components/blocks/ProductBenefits'), { ssr: true })
const ProductComparison = dynamic(() => import('@components/blocks/ProductComparison'), {
  ssr: true,
})
const TechSpecs = dynamic(() => import('@components/blocks/TechSpecs'), { ssr: true })
const ContactForm = dynamic(() => import('@components/blocks/ContactForm'), { ssr: true })
const Text = dynamic(() => import('@components/blocks/Text'), { ssr: true })
const SavingsCalculator = dynamic(() => import('@components/blocks/SavingsCalculator'), {
  ssr: true,
})
const IntakeForm = dynamic(() => import('@components/blocks/IntakeForm'), { ssr: true })
const UniqueSellingPoints = dynamic(() => import('@components/blocks/UniqueSellingPoints'), {
  ssr: true,
})
const TwoColumnText = dynamic(() => import('@components/blocks/TwoColumnText'), { ssr: true })

const attributesAdapter = (json: object) => {
  return { ...json, ...delve(json, 'attributes') }
}

const dataAdapter = (json: object) => {
  return { ...json, ...delve(json, 'data') }
}

const withImageAdapter = (json: object) => {
  const image = delve(json, 'image.data.attributes')
  return { ...json, image }
}

const withImagesAdapter = (
  json: object,
  key = 'images',
  lookupKey: string | null = null,
  mapFunc = withImageAdapter,
) => {
  return { ...json, [key]: (delve(json, lookupKey ?? key) ?? []).map(mapFunc) }
}

const statisticsAdapter = (json: object) => {
  return { ...json, statistics: (delve(json, 'statistics.data') ?? []).map(attributesAdapter) }
}

const withCategoryAdapter = (json: object) => {
  return { ...json, category: delve(json, 'category.data.attributes') }
}

const withSpecificationsAdapter = (json: object) => {
  let specifications = (delve(json, 'specifications') ?? []).map(dataAdapter)
  specifications = specifications.map((spec: object) => withCategoryAdapter(spec))
  return { ...json, specifications }
}

const productAdapter = (json: object) => {
  let product = attributesAdapter(json)
  product = withImageAdapter(product)
  product = withImagesAdapter(product, 'images', 'images.data', attributesAdapter)
  product = withSpecificationsAdapter(product)
  return product
}

const withProductAdapter = (json: object, key = 'product', lookupKey = 'product.data') => {
  return { ...json, [key]: productAdapter(delve(json, lookupKey)) }
}

const BlockManager = ({ blocks, section, theme }: any) => {
  return (
    <>
      {blocks.map(({ __component, ...rest }: any, index: number) => {
        let Block
        let props = { ...rest }
        props.theme = !isNullOrUndefined(props.theme) ? props.theme : theme

        switch (__component) {
          case 'blocks.heading':
            Block = Heading
            break
          case 'blocks.highlights':
            Block = Highlights
            props = withImagesAdapter(props, 'highlights')
            break
          case 'blocks.kiyoh':
            Block = Kiyoh
            break
          case 'blocks.button':
            Block = Button
            break
          case 'blocks.faq':
            Block = Faq
            break
          case 'blocks.tagline':
            Block = Tagline
            break
          case 'blocks.html':
            Block = Html
            break
          case 'blocks.image':
            Block = Image
            props = withImageAdapter(props)
            break
          case 'blocks.image-with-flare':
            Block = ImageWithFlare
            props = withImageAdapter(props)
            break
          case 'blocks.image-with-bubbles':
            Block = ImageWithBubbles
            props = withImageAdapter(props)
            break
          case 'blocks.image-with-text':
            Block = ImageWithText
            props = withImageAdapter(props)
            break
          case 'blocks.image-slideshow':
            Block = ImageSlideshow
            props = withImagesAdapter(props, 'images', 'images.data', attributesAdapter)
            break
          case 'blocks.timeline':
            Block = Timeline
            break
          case 'blocks.savings-calculator':
            Block = SavingsCalculator
            break
          case 'blocks.unique-selling-points':
            Block = UniqueSellingPoints
            break
          case 'blocks.features':
            Block = Features
            break
          case 'blocks.text':
            Block = Text
            break
          case 'blocks.statistics':
            Block = Statistics
            props = statisticsAdapter(props)
            break
          case 'blocks.info-blocks':
            Block = InfoBlocks
            break
          case 'blocks.testimonials':
            Block = Testimonials
            props = withImagesAdapter(props, 'testimonials')
            break
          case 'blocks.brand':
            Block = Brand
            break
          case 'blocks.contact-form':
            Block = ContactForm
            break
          case 'blocks.intake-form':
            Block = IntakeForm
            break
          case 'blocks.product-benefits':
            Block = ProductBenefits
            props = withProductAdapter(props)
            break
          case 'blocks.product-comparison':
            Block = ProductComparison
            props = withProductAdapter(props, 'leftProduct', 'leftProduct.data')
            props = withProductAdapter(props, 'rightProduct', 'rightProduct.data')
            break
          case 'blocks.tech-specs':
            Block = TechSpecs
            props = withProductAdapter(props)
            break
          case 'blocks.text-with-icon':
            Block = TextWithIcon
            break
          case 'blocks.two-column-text':
            Block = TwoColumnText
            break
          default:
            console.log(__component, 'not found')
            return null
            break
        }

        // eslint-disable-next-line react/no-array-index-key
        const key = `${section}-${index}`
        return <Block key={key} {...props} />
      })}
    </>
  )
}

BlockManager.defaultProps = {
  blocks: [],
}

export default BlockManager
