import Section from '@components/blocks/Section'
import BlockManager from '@components/shared/BlockManager'

const SectionManager = ({ sections, theme }: any) => {
  return (
    <>
      {sections.map((section: any) => {
        const key = `section-${section.name}`
        const sectionsKey = `${key}-sections`
        const blocksKey = `${key}-blocks`

        const Sections = section.sections ? (
          <SectionManager key={sectionsKey} theme={theme} sections={section.sections} />
        ) : null

        const Blocks = section.blocks ? (
          <BlockManager key={blocksKey} theme={theme} blocks={section.blocks} section={blocksKey} />
        ) : null

        return (
          <Section {...section} key={key}>
            {section.showSectionsBeforeBlocks ?? true ? (
              <>
                {Sections}
                {Blocks}
              </>
            ) : (
              <>
                {Blocks}
                {Sections}
              </>
            )}
          </Section>
        )
      })}
    </>
  )
}

SectionManager.defaultProps = {
  sections: [],
}

export default SectionManager
