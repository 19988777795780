import Container from '@components/blocks/Container'
import SectionManager from '@components/shared/SectionManager'

const ContainerManager = ({ containers, theme }: any) => {
  return (
    <>
      {containers.map((container: any) => {
        return (
          <Container {...container} key={`container-${container.name}`} theme={theme}>
            {container.sections && <SectionManager theme={theme} sections={container.sections} />}
          </Container>
        )
      })}
    </>
  )
}

ContainerManager.defaultProps = {
  containers: [],
}

export default ContainerManager
