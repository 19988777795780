import IImage, { IImageSrc } from '@common/Image.interface'
import ImageSize from '@components/shared/ImageSize'

type IResolveImageSize = (image: IImage, size: ImageSize) => IImageSrc

const resolveImageSize: IResolveImageSize = (image, size) => {
  return image?.formats[size] ?? image
}

export default resolveImageSize
