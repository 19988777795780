import delve from 'dlv'

import sectionAdapter from './section'

const containerAdapter = (json: object) => {
  const container = delve(json, 'attributes')
  const sections = (delve(container, 'sections.data') ?? []).map(sectionAdapter)
  return { ...container, sections }
}

export default containerAdapter
