import IImage from '@common/Image.interface'
import Props from '@common/Props.interface'
import { Theme } from '@components/shared'
import ImageSize from '@components/shared/ImageSize'
import classNames from '@utils/classNames'
import isNullOrUndefined from '@utils/isNullOrUndefined'
import resolveImageSize from '@utils/resolveImageSize'
import Image from 'next/image'

export interface HeroProps extends Props {
  image?: IImage | null
  imageAnchor?:
    | 'left'
    | 'center'
    | 'right'
    | 'bottom'
    | 'top'
    | 'left-bottom'
    | 'left-top'
    | 'right-bottom'
    | 'right-top'
  fullscreen?: boolean
  showFlare?: boolean
}

const THEME_MAPS: Record<Theme, string> = {
  [Theme.ORANGE]: 'bg-orange-500 text-white',
  [Theme.GREEN]: 'bg-green-500 text-white',
}

const Hero = ({
  theme,
  image = null,
  imageAnchor = 'center',
  children,
  showFlare = false,
  fullscreen = false,
}: HeroProps) => {
  const spacing = !isNullOrUndefined(image)
    ? 'pt-header-mobile md:pt-header'
    : 'mt-header-mobile md:mt-header'

  let height = ''
  if (fullscreen) {
    height =
      image !== null
        ? 'h-screen'
        : 'h-[calc(100vh-theme(spacing.header-mobile))] md:h-[calc(100vh-theme(spacing.header))]'
  }

  const themeClassName = !isNullOrUndefined(theme)
    ? THEME_MAPS[theme!.toUpperCase() as Theme]
    : null

  const sizedImage = image !== null ? resolveImageSize(image, ImageSize.XLARGE) : ''

  return (
    <div className={classNames('relative flex', spacing, height, themeClassName)}>
      {sizedImage && (
        <Image
          src={sizedImage.url}
          priority
          fill
          placeholder="blur"
          blurDataURL={image?.blurhash}
          className={`object-cover object-${imageAnchor}`}
          alt={image?.alternativeText ?? ''}
        />
      )}
      <div
        className={classNames(
          'relative flex h-full w-full items-stretch justify-center xs:items-center xs:justify-end',
          sizedImage ? 'backdrop-blur-sm' : null,
        )}
      >
        {showFlare && (
          <div className="absolute bottom-[calc((100vh_-_5rem)_*_-0.095)] hidden h-[calc(100vh-theme(height.header))] w-screen bg-[url('/swirl.svg')] bg-contain bg-[right_-1rem_bottom] bg-no-repeat md:block" />
        )}
        {children}
      </div>
    </div>
  )
}

export default Hero
