import delve from 'dlv'

import containerAdapter from './container'

const heroAdapter = (json: object) => {
  const hero = delve(json, 'attributes')
  const image = delve(hero, 'image.data.attributes')
  const containers = (delve(hero, 'containers.data') ?? []).map(containerAdapter)
  return { ...hero, image, containers }
}

export default heroAdapter
